import styled from 'styled-components'

import { breakpoints } from '../../utils'

export const TunnelHeader = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 6rem;
  padding: 0 1.75rem;
  width: 100%;

  ${breakpoints.up('sm')} {
    margin-bottom: 1.25rem;
  }

  ${breakpoints.between('sm', 'md')} {
    justify-content: center;
  }
`
