import type { ValueOf } from 'type-fest'

export const ActionType = {
  UPDATE_COMPANY_INFO: 'UPDATE_COMPANY_INFO',
  UPDATE_MERCHANT_GROUP_INFO: 'UPDATE_MERCHANT_GROUP_INFO',
  UPDATE_SALESFORCE_INFO: 'UPDATE_SALESFORCE_INFO',
  UPDATE_USER_CONTACT: 'UPDATE_USER_CONTACT',
} as const

export type ActionType = ValueOf<typeof ActionType>
