import React from 'react'

import styled from 'styled-components'

import { Button, COLORS } from '@themenu/design-system'

const ButtonTransparent = styled(({ ...props }) => <Button size={32} {...props} />)`
  background: transparent;
  border: 1px solid ${COLORS.WHITE['100']};
  :hover {
    background: transparent;
  }
`

export default ButtonTransparent
