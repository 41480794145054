import { CompanyInfo } from '../OnboardingState'
import { SalesforceInfo } from '@tunnel-types/globals'

export function parseSalesforceInfoToCompany(existingSalesforceInfo: SalesforceInfo): CompanyInfo {
  return {
    name: existingSalesforceInfo.account?.name || '',
    cityofRegistration: existingSalesforceInfo.account?.billing_info.immatriculation_city || '',
    legalName: existingSalesforceInfo.account?.billing_info.company_legal_name || '',
    siret: existingSalesforceInfo.account?.billing_info.immatriculation || '',
    socialForm: existingSalesforceInfo.account?.company_type || '',
    vatNumber: existingSalesforceInfo.account?.billing_info.vat_number || '',
    address: {
      address: existingSalesforceInfo.account?.billing_address.street || '',
      additionalAddress: existingSalesforceInfo.account?.billing_address.street2 || '',
      postalCode: existingSalesforceInfo.account?.billing_address.zip || '',
      city: existingSalesforceInfo.account?.billing_address.city || '',
      country: existingSalesforceInfo.account?.billing_address.country || '',
    },
    owner: existingSalesforceInfo.owner || undefined,
  }
}
