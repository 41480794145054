import React, { FunctionComponent, ReactNode, useState } from 'react'

import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Box, Button } from '@themenu/design-system'

import { Company } from '@tunnel-types/globals'

const intlMessages = defineMessages({
  loadMore: {
    id: 'drawer.common.load_more',
  },
})

const ScrollView = styled.div`
  overflow-y: scroll;
  height: 100%;
  width: 100%;
`

const StyledButton = styled(({ children, ...props }) => <Button {...props}>{children}</Button>)`
  min-width: 140px;
`

interface ScrollListProps {
  items: Company[]
  loadMore: () => Promise<void>
  renderItem: (item: Company) => ReactNode
}

const ScrollList: FunctionComponent<ScrollListProps> = ({ items, loadMore, renderItem }) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleOnLoadMore = async (): Promise<void> => {
    setIsLoading(true)
    await loadMore()
    setIsLoading(false)
  }

  return (
    <Box>
      <ScrollView>
        {items.map((item, index) => (
          <React.Fragment key={index}>{renderItem(item)}</React.Fragment>
        ))}
      </ScrollView>
      <Box display="flex" justifyContent="center" mt={24}>
        {items.length > 0 && (
          <StyledButton onClick={handleOnLoadMore} loading={isLoading}>
            {intl.formatMessage(intlMessages.loadMore)}
          </StyledButton>
        )}
      </Box>
    </Box>
  )
}

export default ScrollList
