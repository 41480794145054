import styled from 'styled-components'

import { breakpoints } from '../../utils'

const TunnelSidebar = styled.div`
  flex-direction: column;
  justify-content: space-between;
  min-width: 328px;
  width: 328px;
  min-height: 100vh;
  padding: 50px 24px;
  // Background
  background: url(/images/sidebar/sidebar-background.png) no-repeat center left,
    url(/images/sidebar/sidebar-ellipsis.png);
  display: none;
  background-repeat: no-repeat, no-repeat;
  background-size: contain;
  background-color: #000;

  display: none;
  ${breakpoints.up('md')} {
    display: flex;
  }
`

export default TunnelSidebar
