import React from 'react'

import { StandardTextFieldProps } from '@material-ui/core/TextField'

import FieldName from './FieldName'
import { StyledFormControl, StyledTextField } from './FormTextInput.style'

export type FormTextInputProps = {
  readOnly?: boolean
} & StandardTextFieldProps

const FormTextInput: React.FunctionComponent<FormTextInputProps> = ({ label, error, readOnly, id, ...props }) => {
  return (
    <StyledFormControl>
      <FieldName error={error}>
        <label htmlFor={id}>{label}</label>
      </FieldName>
      <StyledTextField {...props} id={id} readOnly={readOnly} inputProps={{ ...props.inputProps, readOnly }} />
    </StyledFormControl>
  )
}

export default FormTextInput
