import { useState, useEffect } from 'react'

/**
 * Inspired from beautiful-hooks
 * source: https://github.com/beautifulinteractions/beautiful-react-hooks/blob/master/src/useMediaQuery.js
 *
 * Accepts a media query string then uses the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia) API to determine if it
 * matches with the current document.
 */
const useMediaQuery: (query: string) => boolean = (query) => {
  const mediaQuery = query.replace(/^@media( ?)/m, '')
  const matchMedia = (mediaQuery: string): MediaQueryList | undefined => {
    return typeof window !== 'undefined' && window?.matchMedia ? window.matchMedia(mediaQuery) : undefined
  }
  const [isVerified, setIsVerified] = useState<boolean>(matchMedia(mediaQuery)?.matches || false)

  useEffect(() => {
    const mediaQueryList = matchMedia(mediaQuery)
    const documentChangeHandler: () => void = () => {
      setIsVerified(mediaQueryList?.matches || false)
    }

    mediaQueryList?.addEventListener?.('change', documentChangeHandler)

    documentChangeHandler()
    return () => {
      mediaQueryList?.removeEventListener?.('change', documentChangeHandler)
    }
  }, [mediaQuery])

  return isVerified
}

export default useMediaQuery
