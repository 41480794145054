import styled from 'styled-components'

import { Typography } from '@themenu/design-system'

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px; /* todo: make spacing rule */
  margin-bottom: 24px; /* todo: make spacing rule */
`

export const StyledText = styled(Typography)`
  width: 80%;
  margin-top: 40px;
`

export const StyledLogoWrapper = styled.div`
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
  display: flex;
  justify-content: center;
`
