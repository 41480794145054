import { ActionType } from '../actions'
import { OnboardingState } from './OnboardingState'
import { parseSalesforceInfoToCompany } from './parsers/parseSalesforceInfoToCompany'
import { parseSalesforceInfoToContact } from './parsers/parseSalesforceInfoToContact'
import { CompanyAction, companyReducer, initialCompanyInfo } from './reducers/companyInfo.reducer'
import { initialMerchantGroupInfo, MerchantGroupAction, merchantGroupReducer } from './reducers/merchantGroup.reducer'
import { initialSalesforceInfo, SalesforceAction, salesforceReducer } from './reducers/salesforceInfo.reducer'
import { initialUserContactState, UserContactAction, userContactReducer } from './reducers/userContact.reducer'
import { SalesforceInfo } from '@src/@types/globals'

export function getInitialOnboardingState(existingSalesforceInfo?: SalesforceInfo): OnboardingState {
  if (existingSalesforceInfo) {
    return {
      userContact: parseSalesforceInfoToContact(existingSalesforceInfo),
      companyInfo: parseSalesforceInfoToCompany(existingSalesforceInfo),
      salesforceInfo: existingSalesforceInfo,
      merchantGroupInfo: initialMerchantGroupInfo,
    }
  }

  return {
    userContact: initialUserContactState,
    companyInfo: initialCompanyInfo,
    salesforceInfo: initialSalesforceInfo,
    merchantGroupInfo: initialMerchantGroupInfo,
  }
}

export type OnboardingAction = UserContactAction | CompanyAction | SalesforceAction | MerchantGroupAction

export function onboardingReducer(state: OnboardingState, action: OnboardingAction): OnboardingState {
  switch (action.type) {
    case ActionType.UPDATE_USER_CONTACT:
      return {
        ...state,
        userContact: userContactReducer(state.userContact, action),
      }

    case ActionType.UPDATE_COMPANY_INFO:
      return {
        ...state,
        companyInfo: companyReducer(state.companyInfo, action),
      }

    case ActionType.UPDATE_SALESFORCE_INFO:
      return {
        ...state,
        salesforceInfo: salesforceReducer(state.salesforceInfo, action),
      }

    case ActionType.UPDATE_MERCHANT_GROUP_INFO:
      return {
        ...state,
        merchantGroupInfo: merchantGroupReducer(state.merchantGroupInfo, action),
      }

    default:
      return { ...state }
  }
}
