import React from 'react'

import styled from 'styled-components'

export const StyledPenIcon = styled((props) => (
  /* anti-pattern code to work between the affiliates and corpo package*/
  <img
    src={
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('../../assets/pen.svg')?.default ?? require('../../assets/pen.svg')
    }
    alt=""
    {...props}
  />
))`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`
