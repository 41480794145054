import { FunctionComponent } from 'react'

import { useRouter } from 'next/router'

import { defineMessages, useIntl, FormattedMessage } from 'react-intl'

import { Box, Button, IconButton, Typography, COLORS } from '@themenu/design-system'

import { config } from '../../config/client'
import { TunnelHeaderBlockPreviousStep, TunnelHeader } from '@shared/components'
import useMediaQuery from '@shared/hooks/useMediaQuery'
import { breakpoints } from '@shared/utils'

const headerMessages = defineMessages({
  alreadyAffiliated: {
    id: 'header.body.already_affiliated',
  },
  loginButtonText: {
    id: 'header.button.redirect_affiliates',
  },
  loginMobileButtonLabel: {
    id: 'header.mobile.button.redirect_affiliates.aria_label',
  },
  previousStepButtonText: {
    id: 'header.button.step_back',
  },
  previousStepLeftArrowAltText: {
    id: 'header.button.step_back.arrow.alt_text',
  },
  previousStepMobileButtonLabel: {
    id: 'header.mobile.button.step_back.aria_label',
  },
})

type Props = {
  shouldDisplayLoginButton: boolean
  shouldDisplayPreviousButton: boolean
}

const Header: FunctionComponent<Props> = ({ shouldDisplayLoginButton, shouldDisplayPreviousButton }) => {
  const intl = useIntl()
  const router = useRouter()
  const isMobile = useMediaQuery(breakpoints.down('xs'))

  const handleOnPreviousStep = (): void => {
    router.back()
  }

  return (
    <TunnelHeader>
      <Box>
        {shouldDisplayPreviousButton && (
          <TunnelHeaderBlockPreviousStep
            ariaLabelText={intl.formatMessage(headerMessages.previousStepMobileButtonLabel)}
            handleOnPreviousStep={handleOnPreviousStep}
            buttonText={intl.formatMessage(headerMessages.previousStepButtonText)}
            images={{
              leftArrow: {
                altText: intl.formatMessage(headerMessages.previousStepLeftArrowAltText),
                src: '/images/header/arrow-left.svg',
              },
            }}
          />
        )}
      </Box>
      {shouldDisplayLoginButton && (
        <Box alignItems="center" display="flex">
          <Typography weight="medium" variant="body">
            <FormattedMessage {...headerMessages.alreadyAffiliated} />
          </Typography>
          {isMobile ? (
            <IconButton
              aria-label={intl.formatMessage(headerMessages.loginMobileButtonLabel)}
              color="DARK_GREY"
              component="a"
              href={config.affiliatesWebAppUrl}
              ml={8}
              name="interface_home"
              size={48}
            />
          ) : (
            <Button component="a" href={config.affiliatesWebAppUrl} size={40} ml={12}>
              <Typography variant="body" color={COLORS.WHITE['100']}>
                <FormattedMessage {...headerMessages.loginButtonText} />
              </Typography>
            </Button>
          )}
        </Box>
      )}
    </TunnelHeader>
  )
}

export default Header
