import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Button, IconButton, COLORS, Typography } from '@themenu/design-system'

import useMediaQuery from '../../hooks/useMediaQuery'
import { breakpoints } from '../../utils'

type ImageProps = {
  altText: string
  src: string
}

type Props = {
  ariaLabelText: string
  buttonText: string
  handleOnPreviousStep: () => void
  images: {
    leftArrow: ImageProps
  }
}

export const StyledArrowLeft = styled.img`
  margin-right: 0.43rem;
  width: 1.12rem;
`

export const PreviousStepButton = styled(({ children, ...props }) => (
  <Button {...props}>
    <Typography variant="body">{children}</Typography>
  </Button>
))`
  align-items: center;
  color: ${COLORS.WHITE['900']};
`

const BlockPreviousStep: FunctionComponent<Props> = ({ ariaLabelText, buttonText, handleOnPreviousStep, images }) => {
  const isMobile = useMediaQuery(breakpoints.down('xs'))

  return (
    <React.Fragment>
      {isMobile ? (
        <IconButton
          aria-label={ariaLabelText}
          color="DARK_GREY"
          name="interface_arrow-left"
          onClick={handleOnPreviousStep}
          size={48}
        />
      ) : (
        <PreviousStepButton onClick={handleOnPreviousStep} size={40} variant="secondary">
          <StyledArrowLeft src={images.leftArrow.src} alt={images.leftArrow.altText} />
          {buttonText}
        </PreviousStepButton>
      )}
    </React.Fragment>
  )
}

export default BlockPreviousStep
