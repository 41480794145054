import { UserContact } from '../OnboardingState'
import { SalesforceInfo } from '@tunnel-types/globals'

export const SALESFORCE_FIRSTNAME_DEFAULT_PLACEHOLDER = 'PRÉNOM À RENSEIGNER'
export const SALESFORCE_LASTNAME_DEFAULT_PLACEHOLDER = 'NOM À RENSEIGNER'

export function parseSalesforceInfoToContact(existingSalesforceInfo: SalesforceInfo): UserContact {
  const { first_name, last_name, email, phone } = existingSalesforceInfo.account?.contact_person ?? {}

  const firstName = first_name?.toUpperCase() === SALESFORCE_FIRSTNAME_DEFAULT_PLACEHOLDER ? '' : first_name
  const lastName = last_name?.toUpperCase() === SALESFORCE_LASTNAME_DEFAULT_PLACEHOLDER ? '' : last_name

  return {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    email: email ?? '',
    phone: phone ?? '',
  }
}
