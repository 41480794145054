import styled from 'styled-components'

import { Box, COLORS } from '@themenu/design-system'

const RecapWrapper = styled(Box)<{
  error: boolean
}>`
  width: 100%;
  padding: 30px;
  border: 1px solid ${({ error }) => (error ? COLORS.PEACH['200'] : COLORS.WHITE['400'])};
  border-radius: 16px;
  margin-bottom: 16px;
`

export default RecapWrapper
