import { createContext, Dispatch, PropsWithChildren, useContext, useMemo, useReducer } from 'react'

import { OnboardingState } from './OnboardingState'
import { getInitialOnboardingState, OnboardingAction, onboardingReducer } from './reducer'
import { SalesforceInfo } from '@src/@types/globals'
import useDispatchLogger from '@src/hooks/useDispatchLogger'

export const OnboardingStoreContext = createContext({
  state: getInitialOnboardingState(),
  dispatch: (_: OnboardingAction): void => void 0,
})

type Props = PropsWithChildren<{
  existingSalesforceInfo: SalesforceInfo | undefined
}>

export function OnboardingStore({ children, existingSalesforceInfo }: Props): JSX.Element {
  const [state, dispatch] = useDispatchLogger(
    useReducer(onboardingReducer, getInitialOnboardingState(existingSalesforceInfo))
  )
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return <OnboardingStoreContext.Provider value={contextValue}>{children}</OnboardingStoreContext.Provider>
}

export function useOnboardingState(): OnboardingState {
  return useContext(OnboardingStoreContext).state
}

export function useDispatchOnboardingAction(): Dispatch<OnboardingAction> {
  return useContext(OnboardingStoreContext).dispatch
}
