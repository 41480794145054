import { TunnelType } from './TunnelType'

import type { ValueOf } from 'type-fest'

export const Step = {
  CONTACT_INFO: 'contact-info',
  COMPANY_INFO: 'company-info',
  CONTRACT_INFO: 'contract-info',
  BANKING_INFO: 'banking-info',
  SIGNATURE: 'signature',
  CNTR_UNAFFILIATED: 'cntr-unaffiliated',
  ALREADY_AFFILIATED: 'already-affiliated',
  MULTIPLE_RESTAURANTS: 'multiple-restaurants',
  SUCCESS: 'success',
} as const

export type Step = ValueOf<typeof Step>

const ORDERED_STEPS: Step[] = [
  Step.CONTACT_INFO,
  Step.COMPANY_INFO,
  Step.CONTRACT_INFO,
  Step.BANKING_INFO,
  Step.SIGNATURE,
  Step.SUCCESS,
]

const ERROR_STEPS: Step[] = [Step.CNTR_UNAFFILIATED, Step.ALREADY_AFFILIATED, Step.MULTIPLE_RESTAURANTS]

export function isValidStep(stepCandidate: unknown, tunnelType: TunnelType): stepCandidate is Step {
  const standaloneSteps = ORDERED_STEPS.concat(ERROR_STEPS)
  const assistedSteps = ORDERED_STEPS.slice(2).concat(ERROR_STEPS)
  return (tunnelType === TunnelType.STANDALONE ? standaloneSteps : assistedSteps).includes(stepCandidate as Step)
}

export function getFirstStep(tunnelType: TunnelType): Step {
  return tunnelType === TunnelType.STANDALONE ? Step.CONTACT_INFO : Step.CONTRACT_INFO
}
