import React from 'react'

import { MenuItem } from '@material-ui/core'
import { StandardTextFieldProps } from '@material-ui/core/TextField'
import styled from 'styled-components'

import { COLORS } from '@themenu/design-system'

import FieldName from './FieldName'
import { StyledFormControl, StyledTextField } from './FormTextInput.style'

export type SelectOptions<T> = Array<{
  value: T
  label: string
}>

const StyledMenuItem = styled(MenuItem)`
  font-size: 15px;
`

const StyledFormSelectInput = styled(({ options, ...props }) => (
  <StyledTextField select {...props}>
    {options.map((option: { label: string; value: string }) => (
      <StyledMenuItem key={option.value} value={option.value} style={{ color: COLORS.PURPLE['900'] }}>
        {option.label}
      </StyledMenuItem>
    ))}
  </StyledTextField>
))<FormSelectInputProps>`
  & div[class*='MuiSelect-select'] {
    height: 20px;
    padding-top: 6px;
    padding-bottom: 8px;
    font-size: 15px;
    color: ${COLORS.PURPLE['900']};
    &:focus {
      background: none;
    }
  }
`

export type FormSelectInputProps = {
  options: SelectOptions<string | number>
  readOnly: boolean
} & StandardTextFieldProps

const FormSelectInput: React.FunctionComponent<FormSelectInputProps> = ({ label, error, readOnly, id, ...props }) => (
  <StyledFormControl>
    <FieldName error={error}>
      <label htmlFor={id}>{label}</label>
    </FieldName>
    <StyledFormSelectInput {...props} id={id} readOnly={readOnly} inputProps={{ ...props.inputProps, readOnly }} />
  </StyledFormControl>
)

export default FormSelectInput
