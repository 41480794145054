import { defineMessages } from 'react-intl'

const progressBarMessages = defineMessages({
  stepContactInfo: {
    id: 'affiliates_onboarding.progress_bar.title_contact_info',
  },
  stepCompanyInfo: {
    id: 'affiliates_onboarding.progress_bar.title_company_info',
  },
  stepContractInfo: {
    id: 'affiliates_onboarding.progress_bar.title_contract_info',
  },
  stepBankingInfo: {
    id: 'affiliates_onboarding.progress_bar.title_banking_info',
  },
  stepContractSignature: {
    id: 'affiliates_onboarding.progress_bar.title_contract_signature',
  },
  stepContractSuccess: {
    id: 'affiliates_onboarding.progress_bar.title_contract_success',
  },
})

export default progressBarMessages
