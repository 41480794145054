import { FunctionComponent } from 'react'

import { InputProps as StandardInputProps } from '@material-ui/core'
import { useField } from 'formik'
import { useIntl } from 'react-intl'

import { convertYupIntlMessage, YupIntlMesssage } from '@i18n/yup'
import FormTextInput, { FormTextInputProps } from '@shared/components/Form/FormTextInput'

type Props = {
  name: string
  inputProps?: StandardInputProps['inputProps']
} & FormTextInputProps

const TextInput: FunctionComponent<Props> = ({ name, label, ...props }) => {
  const intl = useIntl()
  const [field, meta] = useField(name)

  const inputError = meta.error ? convertYupIntlMessage(intl, meta.error as YupIntlMesssage | string) : undefined

  return (
    <FormTextInput
      label={label}
      error={!!meta.error && meta.touched}
      helperText={meta.touched && inputError}
      {...field}
      {...props}
    />
  )
}

export default TextInput
