import { ActionType } from '../../actions'
import { UserContact } from '../OnboardingState'

export const initialUserContactState: UserContact = {
  email: '',
  phone: '',
  lastName: '',
  firstName: '',
}

export type UserContactAction = {
  type: typeof ActionType.UPDATE_USER_CONTACT
  payload: UserContact
}

export function userContactReducer(state: UserContact, action: UserContactAction): UserContact {
  switch (action.type) {
    case ActionType.UPDATE_USER_CONTACT: {
      const { email, phone, lastName, firstName } = action.payload
      return { ...state, email, phone, lastName, firstName }
    }

    default: {
      return state
    }
  }
}
