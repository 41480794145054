import React, { FunctionComponent, ReactNode } from 'react'

import IconButton from '@material-ui/core/IconButton'

import { Typography } from '@themenu/design-system'

import { Container, BodyContainer, CloseContainer, Header } from './TunnelDrawer.style'

export interface Props {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  closeIcon: {
    altText: string
    src: string
  }
  title: string
}

const TunnelDrawer: FunctionComponent<Props> = ({ children, isOpen, onClose, closeIcon, title }) => {
  return (
    <Container classes={{ modal: 'modal', paper: 'paper' }} anchor="right" open={isOpen} onClose={onClose}>
      <Header>
        <CloseContainer>
          <IconButton onClick={() => onClose()}>
            <img alt={closeIcon.altText} height={24} width={24} src={closeIcon.src} />
          </IconButton>
        </CloseContainer>
        <Typography variant="h2">{title}</Typography>
      </Header>
      <BodyContainer>{children}</BodyContainer>
    </Container>
  )
}

export default TunnelDrawer
