import React, { FunctionComponent } from 'react'

import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

import { Box, COLORS, Typography } from '@themenu/design-system'

import ArrowForwardIcon from '../../assets/arrow-forward.svg'

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid ${COLORS.WHITE['300']};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  svg {
    fill: ${COLORS.WHITE['900']};
  }

  :hover {
    border-bottom: 3px solid ${COLORS.PURPLE['900']};

    svg {
      fill: ${COLORS.PURPLE['900']};
    }
  }
`

interface Props {
  firstLineText: string
  isLoading?: boolean
  onClick: () => void
  secondLineText?: string
  title: string
}

const ListItem: FunctionComponent<Props> = ({ firstLineText, isLoading = false, onClick, secondLineText, title }) => {
  return (
    <Container onClick={onClick}>
      <Box>
        <Typography weight="medium" variant="body">
          {title}
        </Typography>
        <Typography color={COLORS.WHITE['900']} variant="footnote">
          {firstLineText}
        </Typography>
        {secondLineText && (
          <Typography color={COLORS.WHITE['900']} variant="footnote">
            {secondLineText}
          </Typography>
        )}
      </Box>
      {isLoading ? <CircularProgress size="20px" /> : <ArrowForwardIcon />}
    </Container>
  )
}

export default ListItem
