import { useCallback } from 'react'

import { useRouter } from 'next/router'

import { isNil, omitBy } from 'lodash'

import { Step } from '../../constants'
import { useOnboardingRouteParams } from './useOnboardingRouteParams'

type RedirectToStep = (nextStep: Step, id?: string | null) => void

export function useNavigateToStep(): RedirectToStep {
  const router = useRouter()
  const { tunnelType: type, salesforceId: id } = useOnboardingRouteParams()

  return useCallback(
    (step) => {
      const { utm_medium = null, utm_source = null, utm_campaign = null, utm_content = null } = Object(router.query)
      const query = omitBy({ id, type, step, utm_medium, utm_source, utm_campaign, utm_content }, isNil)

      router.push({ query }, undefined, { shallow: true })
    },
    [router, type, id]
  )
}
