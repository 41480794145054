import React, { ChangeEvent, FunctionComponent } from 'react'

import { CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import { StandardTextFieldProps } from '@material-ui/core/TextField'
import styled from 'styled-components'

import { Box, COLORS } from '@themenu/design-system'

export const StyledTextField = styled(TextField)<StandardTextFieldProps>`
  & input::placeholder {
    color: ${COLORS.WHITE['900']};
  }

  & input {
    color: ${COLORS.PURPLE['900']};
    font-size: 15px;
    height: 20px;
    padding-bottom: 13px;
  }

  & input::placeholder {
    opacity: 1;
  }

  width: 100%;
`

interface Props {
  id: string
  searchIcon: {
    altText: string
    src: string
  }
  isLoading?: boolean
  placeholder: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const FormSearchInput: FunctionComponent<Props> = ({ id, isLoading = false, onChange, placeholder, searchIcon }) => {
  return (
    <StyledTextField
      id={id}
      placeholder={placeholder}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box mr={4}>
              {isLoading ? (
                <CircularProgress size="16px" />
              ) : (
                <img alt={searchIcon.altText} height="16px" width="16px" src={searchIcon.src} />
              )}
            </Box>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default FormSearchInput
