export const SalesforceAccountStatus = {
  CONTRACT_SIGNED: 'Signé',
  CONVERTED: 'Converti',
} as const

const ALREADY_SIGNED_STATUSES: string[] = [SalesforceAccountStatus.CONTRACT_SIGNED, SalesforceAccountStatus.CONVERTED]

export function isAccountAlreadySigned(statusCandidate: string | undefined): boolean {
  return !!statusCandidate && ALREADY_SIGNED_STATUSES.includes(statusCandidate)
}
