import { FunctionComponent } from 'react'

import { useField } from 'formik'
import { useIntl } from 'react-intl'

import { convertYupIntlMessage, YupIntlMesssage } from '@i18n/yup'
import FormPhoneInput, { FormPhoneInputProps } from '@shared/components/Form/FormPhoneInput'

type Props = FormPhoneInputProps & {
  name: string
}

const FormikPhoneInput: FunctionComponent<Props> = ({ name, label, ...props }) => {
  const intl = useIntl()
  const [field, meta] = useField(name)

  const inputError = meta.error ? convertYupIntlMessage(intl, meta.error as YupIntlMesssage | string) : undefined

  return (
    <FormPhoneInput
      label={label}
      error={!!meta.error && meta.touched}
      helperText={meta.touched && inputError}
      {...field}
      {...props}
    />
  )
}

export default FormikPhoneInput
