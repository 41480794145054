/* eslint-disable react/display-name */
import { FunctionComponent, ReactNode } from 'react'

import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Box, Button, Typography, COLORS } from '@themenu/design-system'

import { config } from '../../config/client'
import { Sidebar } from '@components/index'
import { redirectWithLocale } from '@i18n/utils'
import { TunnelParentContainer, TunnelViewContainer } from '@shared/components'
import useMediaQuery from '@shared/hooks/useMediaQuery'
import { breakpoints } from '@shared/utils'

const intlMessages = defineMessages({
  greetings: {
    id: 'qualification_view.greetings',
  },
  button: {
    id: 'qualification_view.button',
  },
})

const ViewContainer = styled(Box)`
  padding: 40px 30px 40px 25px;
  text-align: center;

  ${breakpoints.up('sm')} {
    max-width: 750px;
    padding: 0 80px 0 80px;
    text-align: initial;
    padding-bottom: 25px;
  }

  ${breakpoints.up('lg')} {
    max-width: 750px;
    padding: 0;
    position: absolute;
    text-align: initial;
    top: 50%;
    transform: translateY(-50%);
  }
`

type Props = {
  children: ReactNode
  title: string | ReactNode
}

const QualificationView: FunctionComponent<Props> = ({ children, title }) => {
  const intl = useIntl()
  const isMobile = useMediaQuery(breakpoints.down('xs'))

  return (
    <TunnelParentContainer>
      <Sidebar />
      <TunnelViewContainer>
        <ViewContainer>
          <Typography variant="h1">{title}</Typography>
          {children}
          <br />
          <Typography color={COLORS.WHITE['900']} variant="body">
            {intl.formatMessage(intlMessages.greetings)}
          </Typography>
          <Box display="flex" justifyContent={isMobile ? 'center' : 'flex-start'} mt={48}>
            <Button component="a" href={redirectWithLocale(config.websiteUrl)}>
              {intl.formatMessage(intlMessages.button)}
            </Button>
          </Box>
        </ViewContainer>
      </TunnelViewContainer>
    </TunnelParentContainer>
  )
}

export default QualificationView
