import styled from 'styled-components'

import { breakpoints } from '../../utils'

export const FormGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
`

export const FormColumn = styled.div<{ fullWidth?: boolean }>`
  flex-grow: 0;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
  flex-basis: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
  padding-right: 12px;
  ${breakpoints.down('xs')} {
    max-width: 100%;
    flex-basis: 100%;
  }
`
