import React, { FunctionComponent } from 'react'

import { TunnelProgressBarProps } from '../TunnelProgressBar'
import { StyledProgressBar, StyledStep, StyledStepDot, StyledStepText } from './DesktopProgressBar.style'

type DesktopProgressBarProps = TunnelProgressBarProps & {
  currentStepIndex: number
}

const DesktopProgressBar: FunctionComponent<DesktopProgressBarProps> = ({
  steps,
  stepLabels,
  currentStep,
  currentStepIndex,
}) => {
  return (
    <StyledProgressBar
      role="progressbar"
      aria-valuenow={currentStepIndex}
      aria-valuemin={0}
      aria-valuemax={steps.length - 1}
      stepCount={steps.length}
      currentStepIndex={currentStepIndex}
    >
      {steps.map((step, index) => {
        const isActive = index <= currentStepIndex
        const isCurrentStep = step === currentStep

        return (
          <StyledStep key={step} stepCount={steps.length} isActive={isActive}>
            <StyledStepDot isActive={isActive} isCurrentStep={isCurrentStep} />
            <StyledStepText isActive={isActive}>{stepLabels[index]}</StyledStepText>
          </StyledStep>
        )
      })}
    </StyledProgressBar>
  )
}

export default DesktopProgressBar
