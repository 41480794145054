import Image from 'next/image'

import styled from 'styled-components'

import { config } from '../../config/client'
import { redirectWithLocale } from '@i18n/utils'

const TunnelSidebarLogo = styled((props) => (
  <div {...props}>
    <a href={`${redirectWithLocale(config.websiteUrl)}/`}>
      <Image src="/images/logo-black.svg" alt="Swile" width={61} height={61} />
    </a>
  </div>
))`
  display: flex;
  justify-content: center;
`

export default TunnelSidebarLogo
