import { Fragment, FunctionComponent, useEffect, useState } from 'react'

import { useIntl } from 'react-intl'

import progressBarMessages from './ProgressBar.i18n'
import { Step, TunnelType } from '@common/constants'
import { TunnelProgressBar } from '@shared/components'
import { MessageDescriptor } from '@tunnel-types/globals'

type ProgressBarProps = {
  currentStep: Step
  tunnelType: TunnelType
}

const FIRST_STEP_INDEX_NON_STANDALONE = 2

type TunnelSteps = Array<{
  stepName: Step
  message: MessageDescriptor
}>

export const AFFILIATE_STEPS: TunnelSteps = [
  {
    stepName: Step.CONTACT_INFO,
    message: progressBarMessages.stepContactInfo,
  },
  {
    stepName: Step.COMPANY_INFO,
    message: progressBarMessages.stepCompanyInfo,
  },
  {
    stepName: Step.CONTRACT_INFO,
    message: progressBarMessages.stepContractInfo,
  },
  {
    stepName: Step.BANKING_INFO,
    message: progressBarMessages.stepBankingInfo,
  },
  {
    stepName: Step.SIGNATURE,
    message: progressBarMessages.stepContractSignature,
  },
]

const ProgressBar: FunctionComponent<ProgressBarProps> = ({ currentStep, tunnelType }) => {
  const intl = useIntl()
  const [steps, setSteps] = useState<TunnelSteps | undefined>(undefined)

  useEffect(() => {
    if (tunnelType !== TunnelType.STANDALONE) {
      setSteps(AFFILIATE_STEPS.slice(FIRST_STEP_INDEX_NON_STANDALONE))
    } else {
      setSteps(AFFILIATE_STEPS)
    }
  }, [tunnelType])

  return (
    <Fragment>
      {steps && (
        <TunnelProgressBar
          currentStep={currentStep as string}
          steps={steps.map((step) => step.stepName)}
          stepLabels={steps.map((step) => intl.formatMessage(step.message))}
        />
      )}
    </Fragment>
  )
}

export default ProgressBar
