import styled from 'styled-components'

import { Box, Button } from '@themenu/design-system'

import { breakpoints } from '@shared/utils'

export const StyledButton = styled(({ children, ...props }) => <Button {...props}>{children}</Button>)`
  align-items: center;
`

export const StyledImage = styled.img`
  height: 24px;
  width: 24px;
`

export const ButtonContainer = styled(Box)`
  padding-right: 16px;
  width: 100%;

  ${breakpoints.up('sm')} {
    padding-right: 0;
    width: auto;
  }
`
