import React from 'react'

import styled from 'styled-components'

import { Typography, COLORS } from '@themenu/design-system'

export const StyledProgressBar = styled.ul<{
  stepCount: number
  currentStepIndex: number
}>`
  width: calc(150px * ${({ stepCount }) => stepCount});
  display: flex;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  justify-content: center;
  margin: 20px auto;
  background: linear-gradient(90deg, #d5d5d5 0%, #d5d5d5 100%),
    linear-gradient(
      90deg,
      #ce55b0 0.01%,
      #f34dac 21.08%,
      #f48e66 38.94%,
      #f0b832 53.45%,
      #b2ce37 72.66%,
      #63d7af 92.36%,
      #1cc2df 108.99%,
      #0580e6 123.02%
    );
  background-size: calc(100% - ${({ currentStepIndex }) => `${150 + currentStepIndex * 150}px`}) 2px,
    calc(100% - 150px) 2px;
  background-repeat: no-repeat;
  background-position: ${({ currentStepIndex }) => `${80 + currentStepIndex * 150}px`} 10px, 80px 10px;
`

export const StyledStepDot = styled(({ isActive, isCurrentStep, ...props }) => <div {...props} />)`
  transition: all 0.2s ease-in;
  line-height: 30px;
  text-align: center;
  width: 24px;
  margin: auto;

  &:before,
  &:after {
    content: ' ';
    line-height: 30px;
    border-radius: 50%;
  }

  &:before {
    width: 24px;
    height: 24px;
    background-color: transparent;
    text-align: center;
    position: absolute;
    opacity: 0.3;
    transform: translate(-12px, -8px);

    transition-delay: ${({ isActive, isCurrentStep }) => (isActive && isCurrentStep ? '0.35s' : '0s')};

    ${({ isCurrentStep }) =>
      isCurrentStep &&
      `
      background-image: ${COLORS.GRADIENTS.RAINBOW};
      background-size: contain;
      background-repeat: no-repeat;
    `}
  }

  &:after {
    width: 8px;
    height: 8px;
    display: block;
    text-align: center;
    margin: 7px auto 7px;
    background-color: ${COLORS.WHITE['100']};
    border: 2px solid ${COLORS.WHITE['400']};
    box-sizing: border-box;
    transition: ${({ isActive, isCurrentStep }) =>
      isActive && isCurrentStep ? 'all 0.2s ease-in' : 'all 0.1s linear'};
    transition-delay: ${({ isActive, isCurrentStep }) => (isActive && isCurrentStep ? '0.35s' : '0s')};
    ${({ isActive, isCurrentStep }) =>
      isActive &&
      `
        box-shadow: ${isCurrentStep ? `0px 2px 4px ${COLORS.PRIMARY['200']}` : 'none'};
        border-color: ${COLORS.PURPLE['900']};
        background-color: ${COLORS.PURPLE['900']};
      `}
  }
`

export const StyledStepText = styled(({ isActive, ...props }) => <Typography {...props} />)`
  font-size: 13px;
  transition: all 0.2s ease-in;
  transition-delay: ${({ isActive }) => (isActive ? '0.35s' : '0s')};
  color: ${({ isActive }) => (isActive ? COLORS.PURPLE['900'] : COLORS.WHITE['400'])};
`

export const StyledStep = styled.li<{
  isActive: boolean
  stepCount: number
}>`
  width: 150px;
  position: relative;
  float: left;
  text-align: center;
  list-style-type: none;
  transition: all 0.2s ease-out;
  transition-delay: 0.4s;
  ${({ isActive }) => `
  color: ${isActive ? COLORS.PRIMARY['200'] : COLORS.WHITE['900']};
  & ${StyledStepText} {
    color: ${isActive ? COLORS.PURPLE['900'] : COLORS.WHITE['400']}};
    font-weight: ${isActive ? '600' : 'normal'};
  }
`}
  &:after {
    content: '';
    position: absolute;
    transition: width 0.4s ease-out;
    width: ${({ isActive }) => (isActive ? '100%' : '0%')};
    height: 1px;
    background-color: ${COLORS.PRIMARY['200']};
    top: 11px;
    left: -50%;
    z-index: -1;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${COLORS.WHITE['300']};
    top: 11px;
    left: -50%;
    z-index: -1;
  }
  &:first-child:after,
  &:first-child:before {
    content: none;
  }
`
