import { ParsedUrlQuery } from 'querystring'

import { readOnboardingRouteParams } from './OnboardingRouteParams'
import { isAccountAlreadySigned } from './SalesforceAccountStatus'
import { TunnelType } from './TunnelType'
import { AccountOrLeadInfo } from '@src/@types/globals'

export type TunnelUseCaseStatus = {
  isUnknownStep: boolean
  isIncomplete: boolean
  isAlreadySigned: boolean
  isValid: boolean
}

export function getTunnelUseCaseStatus(
  query: ParsedUrlQuery,
  salesforceAccount: AccountOrLeadInfo | undefined
): TunnelUseCaseStatus {
  const params = readOnboardingRouteParams(query)
  const isIncomplete = params.tunnelType !== TunnelType.STANDALONE && !params.salesforceId
  const isAlreadySigned = isAccountAlreadySigned(salesforceAccount?.status)

  const isRouteStepValid = query.step === params.currentStep
  const isRouteTypeValid = query.type === params.tunnelType
  const isUnknownStep = !(isAlreadySigned || isIncomplete) && !(isRouteStepValid && isRouteTypeValid)

  return {
    isUnknownStep,
    isIncomplete,
    isAlreadySigned,
    isValid: !(isUnknownStep || isIncomplete || isAlreadySigned),
  }
}
