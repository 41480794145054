import { TextField, FormControl } from '@material-ui/core'
import styled from 'styled-components'

import { COLORS } from '@themenu/design-system'

import { FormTextInputProps } from './FormTextInput'

export const StyledTextField = styled(TextField)<FormTextInputProps>`
  border: none;
  padding-bottom: 13px !important; /* needed to override MUI in corpo onboarding */

  /* Override Material UI underline border-color*/
  & *[class*='MuiInput-underline']:hover {
    &::before,
    &::after {
      border-color: ${COLORS.PURPLE['900']} !important;
    }
  }

  & input {
    font-family: Graphik;
    font-size: 15px;
    color: ${COLORS.PURPLE['900']};
    height: 20px;
    ${({ readOnly }) => !readOnly && 'padding-bottom: 8px;'}
    &::placeholder {
      color: ${COLORS.WHITE['900']};
      opacity: 1;
    }
  }

  ${({ readOnly }) =>
    readOnly &&
    `
      & *[class*='MuiInput-underline'] {
        &::before, &::after {
          border: 0 !important;
        }
      }
      & svg[class*='MuiSelect-icon'] {
        display: none;
      }
  `}
`

export const StyledFormControl = styled(FormControl)`
  width: 100%;
  & p[class*='MuiFormHelperText-root'] {
    color: ${COLORS.PEACH['600']};
  }
  & input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
`
