import React, { FunctionComponent } from 'react'

import { Box } from '@themenu/design-system'

import { TunnelProgressBarProps } from '../TunnelProgressBar'
import { StyledLinearProgress, StyledStepProgress, StyledStepLabel } from './MobileProgressBar.style'

const STEP_OFFSET = 1

type MobileProgressBarProps = TunnelProgressBarProps & {
  currentStepIndex: number
}

export const MobileProgressBar: FunctionComponent<MobileProgressBarProps> = ({
  steps,
  stepLabels,
  currentStepIndex,
}) => {
  return (
    <React.Fragment>
      <Box display="flex" justifyContent="center" mt={12} mb={12} pl={20} pr={20}>
        <StyledStepLabel>{stepLabels[currentStepIndex]}</StyledStepLabel>
        <StyledStepProgress>
          {currentStepIndex + STEP_OFFSET}/{steps.length}
        </StyledStepProgress>
      </Box>
      <StyledLinearProgress
        value={Math.round(((currentStepIndex + STEP_OFFSET) / steps.length) * 100)}
        aria-valuenow={currentStepIndex}
        aria-valuemin={0}
        aria-valuemax={steps.length - 1}
      />
    </React.Fragment>
  )
}
