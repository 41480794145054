import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import useMediaQuery from '../../hooks/useMediaQuery'
import { breakpoints } from '../../utils'
import DesktopProgressBar from './DesktopProgressBar/DesktopProgressBar'
import { MobileProgressBar } from './MobileProgressBar/MobileProgressBar'

export type TunnelProgressBarProps = {
  steps: Array<string>
  stepLabels: Array<string>
  currentStep: string
}

export const StyledWrapper = styled.div`
  width: 100%;
`

const TunnelProgressBar: FunctionComponent<TunnelProgressBarProps> = (props) => {
  const isTablet = useMediaQuery(breakpoints.down('md'))
  const currentStepIndex = props.steps.indexOf(props.currentStep)

  return (
    <StyledWrapper>
      {isTablet ? (
        <MobileProgressBar {...props} currentStepIndex={currentStepIndex} />
      ) : (
        <DesktopProgressBar {...props} currentStepIndex={currentStepIndex} />
      )}
    </StyledWrapper>
  )
}

export default TunnelProgressBar
