import React, { ReactElement } from 'react'

import { Box, Typography } from '@themenu/design-system'

export const RecapHeader: React.FunctionComponent<{
  title: string
  action?: ReactElement
}> = ({ title, action }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={16}>
      <Typography variant="h2">{title}</Typography>
      {action}
    </Box>
  )
}

export default RecapHeader
