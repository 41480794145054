import { ActionType } from '../../actions'
import { CompanyInfo } from '../OnboardingState'

export const initialCompanyInfo: CompanyInfo = {
  locationsNumber: '',
  cityofRegistration: '',
  legalName: '',
  name: '',
  socialForm: '',
  siret: '',
  vatNumber: '',
  address: {
    address: '',
    additionalAddress: '',
    postalCode: '',
    city: '',
    country: '',
  },
  owner: undefined,
}

export type CompanyAction = {
  type: typeof ActionType.UPDATE_COMPANY_INFO
  payload: CompanyInfo
}

export function companyReducer(state: CompanyInfo, action: CompanyAction): CompanyInfo {
  switch (action.type) {
    case ActionType.UPDATE_COMPANY_INFO: {
      const { cityofRegistration, legalName, locationsNumber, name, socialForm, siret, vatNumber, address, owner } =
        action.payload
      return {
        ...state,
        cityofRegistration,
        legalName,
        locationsNumber,
        name,
        socialForm,
        siret,
        vatNumber,
        address,
        owner,
      }
    }

    default: {
      return state
    }
  }
}
