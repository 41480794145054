import { ActionType } from '../../actions'
import { SalesforceInfo } from '@tunnel-types/globals'

export const initialSalesforceInfo: SalesforceInfo = {
  account: {
    sfid: '',
    name: '',
    company_type: '',
    meal_voucher_commission_percent: 3.5,
    contract_signed_on: null,
    status: '',
    share_capital_cents: {
      value: 0,
      currency: {
        iso_3: 'EUR',
        symbol: '€',
        title: 'euros',
      },
    },
    billing_info: {
      iban: null,
      bic: null,
    },
    billing_address: {
      street: '',
      zip: '',
      city: '',
      country: '',
    },
    debit_mandate: {
      mandate_number: '',
    },
    contact_person: {},
  },
  owner: null,
}

export type SalesforceAction = {
  type: typeof ActionType.UPDATE_SALESFORCE_INFO
  payload: SalesforceInfo
}

export function salesforceReducer(state: SalesforceInfo, action: SalesforceAction): SalesforceInfo {
  switch (action.type) {
    case ActionType.UPDATE_SALESFORCE_INFO: {
      const { account, owner } = action.payload
      return {
        ...state,
        account,
        owner,
      }
    }

    default: {
      return state
    }
  }
}
