import { useEffect, useRef, Dispatch } from 'react'

import Sentry from '@lib/sentry'

type State = unknown
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DispacthLogger = [State, Dispatch<any>]

// middleware to log action informations to Sentry
const useDispatchLogger = <T extends DispacthLogger>([state, dispatch]: T): T => {
  const actionRef = useRef<unknown>()

  const newDispatchRef = useRef<Dispatch<unknown>>((action) => {
    // we 'remember' our action so it can be used in the useEffect
    actionRef.current = action
    // we dispatch like the middleware did nothing
    dispatch(action)
  })

  // every time the state change we will send the action that did it to Sentry
  useEffect(() => {
    const action = actionRef.current
    if (action) {
      Sentry.setContext('action', { action })
    }
  }, [state])

  return [state, newDispatchRef.current] as T
}

export default useDispatchLogger
