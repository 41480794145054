import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { COLORS } from '@themenu/design-system'

export const StyledStepLabel = styled.div`
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  flex-grow: 0;
  margin-left: calc(100% / 12);
  max-width: calc((100% / 12) * 10);
  flex-basis: calc((100% / 12) * 10);
`

export const StyledStepProgress = styled.div`
  font-weight: 500;
  text-align: right;
  max-width: calc(100% / 12);
  flex-basis: calc(100% / 12);
  flex-grow: 0;
`

export const StyledLinearProgress = styled<FunctionComponent<{ value: number }>>((props) => {
  return (
    <div role="progressbar" {...props}>
      <div />
    </div>
  )
})`
  background-color: ${COLORS.WHITE['300']};
  height: 4px;
  overflow: hidden;
  position: relative;

  & > div {
    background: linear-gradient(
      90deg,
      #ce55b0 0.01%,
      #f34dac 21.08%,
      #f48e66 38.94%,
      #f0b832 53.45%,
      #b2ce37 72.66%,
      #63d7af 92.36%,
      #1cc2df 108.99%,
      #0580e6 123.02%
    );
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
    transition: transform 0.4s ease-out;
    transform-origin: left;
    transform: translateX(${({ value }) => value && -(100 - value)}%);
  }
`
