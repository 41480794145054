import { defineMessages } from 'react-intl'

export const intlValidation = defineMessages({
  errorUnknown: {
    id: 'common.errors.mixed.unknown',
  },
  default: {
    id: 'common.errors.mixed.default',
  },
  missingInformations: {
    id: 'common.errors.mixed.missing_informations',
  },
  required: {
    id: 'common.errors.mixed.required',
  },
  email: {
    id: 'common.errors.string.email',
  },
  phone: {
    id: 'common.errors.string.phone',
  },
  siret: {
    id: 'common.errors.string.siret',
  },
  numberMin: {
    id: 'common.errors.number.min',
  },
  numberMax: {
    id: 'common.errors.number.max',
  },
  stringMin: {
    id: 'common.errors.string.min',
  },
  stringMax: {
    id: 'common.errors.string.max',
  },
  dateMin: {
    id: 'common.errors.date.min',
  },
  dateMax: {
    id: 'common.errors.date.max',
  },
  termsOfServices: {
    id: 'common.errors.custom.terms_of_services',
  },
})

export const intlCommonForm = defineMessages({
  submit: {
    id: 'common.form.submit',
  },
  confirm: {
    id: 'common.form.confirm',
  },
  edit: {
    id: 'common.form.edit',
  },
  cancel: {
    id: 'common.form.cancel',
  },
  firstName: {
    id: 'common.form.first_name.label',
  },
  firstNamePlaceholder: {
    id: 'common.form.first_name.placeholder',
  },
  lastName: {
    id: 'common.form.last_name.label',
  },
  lastNamePlaceholder: {
    id: 'common.form.last_name.placeholder',
  },
  email: {
    id: 'common.form.email.label',
  },
  emailPlaceholder: {
    id: 'common.form.email.placeholder',
  },
  phone: {
    id: 'common.form.phone.label',
  },
  phonePlaceholder: {
    id: 'common.form.phone.placeholder',
  },
  street: {
    id: 'common.form.street.label',
  },
  streetPlaceholder: {
    id: 'common.form.street.placeholder',
  },
  additionalAddress: {
    id: 'common.form.street_2.label',
  },
  additionalAddressPlaceholder: {
    id: 'common.form.street_2.placeholder',
  },
  postalCode: {
    id: 'common.form.postal_code.label',
  },
  postalCodePlaceholder: {
    id: 'common.form.postal_code.placeholder',
  },
  city: {
    id: 'common.form.city.label',
  },
  cityPlaceholder: {
    id: 'common.form.city.placeholder',
  },
})
