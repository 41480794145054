import { ParsedUrlQuery } from 'querystring'

import { Step, TunnelType } from '../../constants'
import { getFirstStep, isValidStep } from './Step'

type UrlQueryParam = ParsedUrlQuery[keyof ParsedUrlQuery]

function readTunnelType(typeCandidate: UrlQueryParam): TunnelType {
  switch (typeCandidate) {
    case TunnelType.ACCOUNT:
      return TunnelType.ACCOUNT
    case TunnelType.LEAD:
      return TunnelType.LEAD
    case TunnelType.STANDALONE:
    default:
      return TunnelType.STANDALONE
  }
}

function readTunnelStep(stepCandidate: UrlQueryParam, tunnelType: TunnelType): Step {
  return isValidStep(stepCandidate, tunnelType) ? stepCandidate : getFirstStep(tunnelType)
}

export type OnboardingRouteParams = {
  tunnelType: TunnelType
  currentStep: Step
  salesforceId: string | null
}

export function readOnboardingRouteParams(query: ParsedUrlQuery): OnboardingRouteParams {
  const tunnelType = readTunnelType(query.type)
  const currentStep = readTunnelStep(query.step, tunnelType)
  const salesforceId = query.id?.toString() ?? null

  return { tunnelType, currentStep, salesforceId }
}
