import { ActionType } from '../../actions'
import { MerchantGroup } from '@tunnel-types/globals'

export const initialMerchantGroupInfo: MerchantGroup = {
  salesforce_account_id: '',
  name: '',
  company_type: '',
  share_capital_cents: {
    value: 0,
    currency: {
      iso_3: 'EUR',
      symbol: '€',
      title: 'euros',
    },
  },
  billing_info: {
    iban: null,
    bic: null,
  },
  billing_address: {
    street: '',
    street2: '',
    city: '',
    zip: '',
    country: '',
    formatted: '',
    name: '',
  },
  contact_person: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  },
}

export type MerchantGroupAction = {
  type: typeof ActionType.UPDATE_MERCHANT_GROUP_INFO
  payload: MerchantGroup
}

export function merchantGroupReducer(state: MerchantGroup, action: MerchantGroupAction): MerchantGroup {
  switch (action.type) {
    case ActionType.UPDATE_MERCHANT_GROUP_INFO:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
