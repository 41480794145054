import DrawerMui from '@material-ui/core/Drawer'
import styled from 'styled-components'

import { Box } from '@themenu/design-system'

import { breakpoints } from '../../utils'

export const BodyContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  padding: 0 40px 25px;
`

export const Container = styled(DrawerMui)`
  &.root {
    background-color: transparent;
  }

  .paper {
    width: 100%;

    ${breakpoints.up('sm')} {
      width: 500px;
    }
  }
`

export const CloseContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`

export const Header = styled.div`
  position: relative;
  padding: 20px 20px 27px 40px;
`
