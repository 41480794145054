/**
 * Inspired from Material UI breakpoints (with less customization options)
 * documentation: https://material-ui.com/customization/breakpoints/
 * file: @material-ui/core/styles/createBreakpoints.js
 * */

type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const screenSizeKeys: Array<ScreenSize> = ['xs', 'sm', 'md', 'lg', 'xl']
export const screenSizes: Record<ScreenSize, number> = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

const down: (value: ScreenSize | number) => string = (value) => {
  if (typeof value === 'number') {
    return `@media (max-width: ${value}px)`
  } else if (value === 'xl') {
    return up('xs')
  } else {
    const maxWidthIndex = screenSizeKeys.indexOf(value) + 1
    const upperBound = screenSizes[screenSizeKeys[maxWidthIndex]]
    return `@media (max-width: ${upperBound}px)`
  }
}

const up: (value: ScreenSize | number) => string = (value) => {
  if (typeof value === 'number') {
    return `@media (min-width: ${value}px)`
  }
  return `@media (min-width: ${screenSizes[value]}px)`
}

const between: (startScreenSize: ScreenSize | number, endScreenSize: ScreenSize | number) => string = (
  startScreenSize,
  endScreenSize
) => {
  const minWidthSize =
    screenSizes[screenSizeKeys[screenSizeKeys.indexOf(startScreenSize as ScreenSize)]] ?? startScreenSize
  const maxWidthSize = screenSizes[screenSizeKeys[screenSizeKeys.indexOf(endScreenSize as ScreenSize)]] ?? endScreenSize

  return `@media (min-width: ${minWidthSize}px) and (max-width: ${maxWidthSize}px)`
}

const only: (key: ScreenSize | number) => string = (key) => {
  return between(key, key)
}

const breakpoints = {
  up,
  down,
  only,
  between,
}

export default breakpoints
