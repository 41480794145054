import styled from 'styled-components'

import { breakpoints } from '../utils'

export const TunnelParentContainer = styled.div`
  display: flex;
  max-height: 100vh;
`

export const TunnelViewContainer = styled.div`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  ${breakpoints.up('md')} {
    overflow: scroll;
    overflow-x: hidden;
  }
`

export const TunnelStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  ${breakpoints.down('md')} {
    padding-bottom: 2rem;
  }
`

const PROGRESS_BAR_HEIGHT = 82
const TOP_BAR_HEIGHT = 0
export const TunnelViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
`

export const TunnelStepWrapper = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  max-width: 860px;
  ${breakpoints.up('md')} {
    margin-bottom: ${PROGRESS_BAR_HEIGHT + TOP_BAR_HEIGHT}px;
  }
`

export const TunnelFormWrapper = styled.div`
  width: 100%;
  padding-left: 56px;
  padding-right: 56px;
  ${breakpoints.down('sm')} {
    padding-right: 0;
    padding-left: 16px;
  }
`
