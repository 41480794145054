import React, { forwardRef, ForwardRefRenderFunction } from 'react'

import 'react-phone-number-input/style.css'
import PhoneInput, { PhoneInputProps } from 'react-phone-number-input'
import styled from 'styled-components'

import { COLORS } from '@themenu/design-system'

import FieldName from './FieldName'
import FormTextInput, { FormTextInputProps } from './FormTextInput'
import { StyledFormControl, StyledTextField } from './FormTextInput.style'

const DEFAULT_PHONE_COUNTRY = 'FR'

export type FormPhoneInputProps = FormTextInputProps &
  Partial<Omit<PhoneInputProps, 'error'>> & {
    error?: boolean
  }

const StyledPhoneInput = styled(({ field, ...props }) => <PhoneInput {...props} />)`
  & {
    position: relative;
  }
  & > .PhoneInputCountry {
    height: 33px;
    top: 0;
    position: absolute;
    z-index: 100;
    margin-left: 5px;
  }
  & .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
  & .PhoneInputCountrySelectArrow {
    margin-top: -3px;
    margin-left: 6px;
    border-color: ${COLORS.PEACH['200']};
  }
  & input {
    padding-bottom: 8px;
  }
`

const ForwardedFormTextInput: React.FunctionComponent<FormTextInputProps> = function (props, ref) {
  return <FormTextInput {...props} inputRef={ref} />
}

const ForwardedFormTextInputRef = forwardRef(ForwardedFormTextInput as ForwardRefRenderFunction<FormTextInputProps>)

const FormPhoneInput: React.FunctionComponent<FormPhoneInputProps> = ({ label, inputProps, id, ...props }) => {
  /* Converts the react-phone-number-input onChange to the formik Onchange */
  const handleOnChange: (value: string) => void = (value) => {
    if (props.onChange && props.name) {
      props.onChange({
        target: { value, name: props.name },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as React.ChangeEvent<any>)
    }
  }

  return (
    <StyledFormControl>
      <FieldName error={props.error}>
        <label htmlFor={id}>{label}</label>
      </FieldName>
      {props.readOnly ? (
        <StyledTextField {...props} id={id} inputProps={{ readOnly: true, ...inputProps }} />
      ) : (
        <StyledPhoneInput
          defaultCountry={DEFAULT_PHONE_COUNTRY}
          id={id}
          inputComponent={ForwardedFormTextInputRef}
          inputProps={{ style: { paddingLeft: 45 }, ...inputProps }}
          {...props}
          onChange={handleOnChange}
        />
      )}
    </StyledFormControl>
  )
}

export default FormPhoneInput
