import { FunctionComponent } from 'react'

import { TextFieldProps } from '@material-ui/core'
import { defineMessages, useIntl } from 'react-intl'

import { Box, Typography, COLORS } from '@themenu/design-system'

import { ButtonContainer, StyledButton, StyledImage } from './SearchCompanyButton.style'
import { FieldName } from '@shared/components'

const LABEL_ID = 'searchCompany'

const intlSearchCompany = defineMessages({
  placeholder: {
    id: 'search_company.company_name.placeholder',
  },
  edit: {
    id: 'search_company.company_name.edit',
  },
  validationError: {
    id: 'search_company.validation.select_company',
  },
})

type Props = {
  companyName?: string
  labelText: string
  onClick: () => void
} & TextFieldProps

const SearchCompanyButton: FunctionComponent<Props> = ({ companyName, error, labelText, onClick }) => {
  const intl = useIntl()

  return (
    <Box>
      <FieldName>
        <label htmlFor={LABEL_ID}>{labelText}</label>
      </FieldName>
      <ButtonContainer>
        <StyledButton id={LABEL_ID} mt={8} onClick={onClick} type="button" variant="secondary">
          {!companyName && (
            <>
              <StyledImage alt="search" src="/images/forms/search.svg" />
              <Typography ml={8} variant="body" color={COLORS.WHITE['900']}>
                {intl.formatMessage(intlSearchCompany.placeholder)}
              </Typography>
            </>
          )}
          {companyName && (
            <>
              <Typography mr={24} variant="body">
                {companyName}
              </Typography>
              <Typography variant="body" color={COLORS.WHITE['900']}>
                {intl.formatMessage(intlSearchCompany.edit)}
              </Typography>
            </>
          )}
        </StyledButton>
      </ButtonContainer>
      {error && (
        <Typography color={COLORS.PEACH['200']} mt={8} variant="footnote">
          {intl.formatMessage(intlSearchCompany.validationError)}
        </Typography>
      )}
    </Box>
  )
}

export default SearchCompanyButton
