import React from 'react'

import styled from 'styled-components'

import { Box, COLORS, Typography } from '@themenu/design-system'

type props = {
  message: string
  avatar: { name: string; image: string }
}

const StyledAvatarDot = styled.div`
  position: absolute;
  bottom: 2px;
  right: 0;
  width: 11px;
  height: 11px;
  user-select: none;
  border-radius: 50%;
  background: ${COLORS.GREEN['600']};
  border: 2px solid ${COLORS.WHITE[100]};
`

const StyledAvatar = styled(({ children, ...props }) => (
  <div {...props}>
    <div>{children}</div>
    <StyledAvatarDot />
  </div>
))`
  position: relative;
  width: 40px;
  height: 40px;
  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    position: relative;
    overflow: hidden;
    user-select: none;
    border-radius: 50%;
  }
`

const AvatarDialogue: React.FunctionComponent<props> = ({ message, avatar }) => {
  return (
    <Box display="flex" mb={36} mt={36}>
      <StyledAvatar>
        <img height={40} width={40} alt="Swile - avatar" src={avatar.image} />
      </StyledAvatar>
      <Box ml={16}>
        <Typography variant="body" weight="medium">
          {avatar.name}
        </Typography>
        <Typography variant="body" color={COLORS.WHITE['900']}>
          {message}
        </Typography>
      </Box>
    </Box>
  )
}
export default AvatarDialogue
