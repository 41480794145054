import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { OnboardingRouteParams, readOnboardingRouteParams } from '../shared/OnboardingRouteParams'
import { getFirstStep, Step } from '../shared/Step'
import { TunnelType } from '../shared/TunnelType'
import { getTunnelUseCaseStatus } from '../shared/TunnelUseCaseStatus'
import { useNavigateToStep } from './useNavigateToStep'
import { ErrorCode } from '@src/common/errors/ErrorCode'
import { config } from '@src/config/client'
import { useOnboardingState } from '@src/store'
import { OnboardingState } from '@src/store/onboarding/OnboardingState'
import { initialCompanyInfo } from '@src/store/onboarding/reducers/companyInfo.reducer'
import { initialSalesforceInfo } from '@src/store/onboarding/reducers/salesforceInfo.reducer'
import { initialUserContactState } from '@src/store/onboarding/reducers/userContact.reducer'

function shouldRedirectToFirstStep(params: OnboardingRouteParams, state: OnboardingState): boolean {
  switch (params.currentStep) {
    case Step.COMPANY_INFO:
      return state.userContact === initialUserContactState

    case Step.CONTRACT_INFO: {
      return params.tunnelType === TunnelType.STANDALONE && state.companyInfo === initialCompanyInfo
    }

    case Step.BANKING_INFO:
      return state.salesforceInfo.account === initialSalesforceInfo.account

    case Step.SIGNATURE:
      return !state.salesforceInfo.account || state.salesforceInfo === initialSalesforceInfo

    default:
      return false
  }
}

export function useReconcileFirstNavigation(): void {
  const router = useRouter()
  const navigateToStep = useNavigateToStep()
  const state = useOnboardingState()

  useEffect(() => {
    if (config.skipTunnelStepCheck) {
      return
    }

    const salesforceAccount = state.salesforceInfo.account
    const query = router.query

    const params = readOnboardingRouteParams(query)
    const { isUnknownStep, isIncomplete, isAlreadySigned } = getTunnelUseCaseStatus(query, salesforceAccount)

    const shouldStartOver = !isUnknownStep && shouldRedirectToFirstStep(params, state)

    if (isIncomplete) {
      router.push({
        pathname: '/missing-information',
        query: { ...query, error: ErrorCode.MISSING_INFORMATION_SALESFORCE_ID },
      })
    } else if (isAlreadySigned) {
      router.push({
        pathname: '/already-affiliated',
        query: { name: salesforceAccount?.name },
      })
    } else if (isUnknownStep) {
      navigateToStep(params.currentStep)
    } else if (shouldStartOver) {
      navigateToStep(getFirstStep(params.tunnelType))
    }
  }, [navigateToStep, router, state])
}
