import { IntlShape } from 'react-intl'

export { intlValidation } from './intlMessages'
import { MessageDescriptor } from '@tunnel-types/globals'

/*
 * Type containing intl error message with the possibility of external values
 * */
export type YupIntlMesssage = MessageDescriptor & {
  values?: Record<string, string | number | boolean | null | undefined | Date>
}

export const convertYupIntlMessage: (intl: IntlShape, message: YupIntlMesssage | string) => string = (
  intl,
  message
) => {
  if (typeof message === 'object' && message.id) {
    const { values, ...messageDescriptor } = message
    return intl.formatMessage(messageDescriptor, values)
  }
  return message as string
}
