import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { COLORS, Typography } from '@themenu/design-system'

const FieldName = styled<FunctionComponent<{ error?: boolean }>>(({ error, ...props }) => (
  <Typography variant="body" {...props} color={error ? COLORS.PEACH['600'] : COLORS.WHITE['900']} />
))`
  font-size: 13px;
`

export default FieldName
