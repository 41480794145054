import { FunctionComponent } from 'react'

import { FormattedMessage } from 'react-intl'

import { Typography, Box } from '@themenu/design-system'

import { config } from '../../config/client'
import TunnelSidebarLogo from './TunnelSidebarLogo'
import { redirectWithLocale } from '@i18n/utils'
import { ButtonTransparent, TunnelSidebar } from '@shared/components'

const Sidebar: FunctionComponent = () => {
  return (
    <>
      <TunnelSidebar>
        <TunnelSidebarLogo />
        <div>
          <Box mb={8}>
            <Typography variant={'body'} color={'WHITE'} weight="medium">
              <FormattedMessage id="sidebar.additional_information" />
            </Typography>
          </Box>
          <ButtonTransparent component="a" href={`${redirectWithLocale(config.websiteUrl)}/contact?reason=affiliate`}>
            <FormattedMessage id="sidebar.contact_us" />
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.04289 7.62627C2.65237 8.01679 2.65237 8.64996 3.04289 9.04048C3.43342 9.43101 4.06658 9.43101 4.45711 9.04048L3.04289 7.62627ZM7.08333 5.00004L7.79044 5.70715C8.18096 5.31662 8.18096 4.68346 7.79044 4.29293L7.08333 5.00004ZM4.45711 0.959601C4.06658 0.569077 3.43342 0.569077 3.04289 0.959601C2.65237 1.35013 2.65237 1.98329 3.04289 2.37381L4.45711 0.959601ZM4.45711 9.04048L7.79044 5.70715L6.37623 4.29293L3.04289 7.62627L4.45711 9.04048ZM7.79044 4.29293L4.45711 0.959601L3.04289 2.37381L6.37623 5.70715L7.79044 4.29293Z"
                fill="white"
              />
            </svg>
          </ButtonTransparent>
        </div>
      </TunnelSidebar>
    </>
  )
}

export default Sidebar
