import { FunctionComponent, ReactNode } from 'react'

import { defineMessages, useIntl } from 'react-intl'

import { TunnelDrawer } from '@shared/components'

const intlMessages = defineMessages({
  closeIconAltText: {
    id: 'drawer.close_button.alt_text',
  },
})
interface Props {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  title: string
}

const Drawer: FunctionComponent<Props> = (props) => {
  const intl = useIntl()

  return (
    <TunnelDrawer
      {...props}
      closeIcon={{
        altText: intl.formatMessage(intlMessages.closeIconAltText),
        src: '/images/drawer/cancel.svg',
      }}
      title={props.title}
    >
      {props.children}
    </TunnelDrawer>
  )
}

export default Drawer
